@import url("https://fonts.googleapis.com/css?family=Exo+2&display=swap&text=ATAKANERMİŞSoftwareDeveloper");

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
}

html,
body,
#root,
main {
  width: 100%;
  height: 100%;
}

main.App-dark {
  background: black;
  color: white;
}

main.App-light {
  background: rgb(245, 245, 245);
  color: black;
}

main.App-light > .content > ul > li > a {
  color: black;
  background: white;
  border: 2px solid black;
  &:hover {
    background: black;
    color: white;
  }
}

main {
  transition: 250ms background, color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Exo 2", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.content {
  padding: 30px;
}

h1 {
  text-align: center;
  font-size: 48px;
  letter-spacing: 3px;
  animation: startup 1s forwards;
}

h2 {
  opacity: 0;
  text-align: center;
  animation: startup 1s 250ms forwards;
  font-weight: 400;
}

ul {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 50px;
  li {
    margin: 15px;
    transform: translateY(-25px);
    opacity: 0;
    animation: links 450ms cubic-bezier(0.17, 0.89, 0.01, 1.27) forwards;
    a {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      border: 2px solid white;
      border-radius: 50%;
      font-size: 24px;
      transition: color, background 250ms;
      &:hover {
        background: white;
        color: black;
      }
    }
  }
}

.button__toggle-theme {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 64px;
  height: 64px;
  background-color: rgba(200, 200, 200, 0.5);
  border-radius: 12px;
  transition: 250ms background;
  &:hover {
    cursor: pointer;
    background-color: rgba(200, 200, 200, 0.4);
  }
}

.App-light .button__toggle-theme {
  color: black;
}

.App-dark .button__toggle-theme {
  color: white;
}

@keyframes links {
  0% {
    transform: translateY(-25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes startup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
